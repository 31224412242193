import { SplitTopperProps } from "design-system/components/toppers/split-topper/split-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SplitPageTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaListProps } from "../primitives/cta-list.props";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { usePageProps } from "frontend/hooks/use-page-props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createSplitPageTopperProps = createContentfulProps<
  SplitPageTopperEntry,
  SplitTopperProps
>(({ entry, createEditAttributes }) => {
  const { request } = usePageProps();
  const { site } = useContentful();

  return {
    theme: entry.fields.theme,
    title: entry.fields.title,
    hiddenTitle:
      request.path.substring(1) === site?.fields.slug
        ? site.fields.title
        : undefined,
    subtitle: entry.fields.subtitle && (
      <RenderContentfulRichText document={entry.fields.subtitle} />
    ),
    mediaAsset: createMediaAssetProps(entry.fields.media),
    ctaList: createCtaListProps(entry.fields.links),
    ctaButton: createCtaLinkProps(entry.fields.ctaButton),

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
    },
  };
});
