import { HeroTeaseProps } from "design-system/components/blocks/hero-tease/hero-tease";
import {
  LegacySearchHit,
  ModernSearchHit,
  SearchDisplay,
  SearchHit,
} from "frontend/types";
import { isSearchHitType } from "./is-search-hit-type";
import { BylinePerson } from "design-system/components/primitives/byline/byline";

export function createSearchHeroTease(data?: SearchHit) {
  if (isSearchHitType<ModernSearchHit>("modern", data)) {
    return createModernSearchHeroTease(data);
  }
  if (isSearchHitType<LegacySearchHit>("legacy", data)) {
    return createLegacySearchHeroTease(data);
  }
}

export function createModernSearchHeroTease(
  data?: ModernSearchHit,
): HeroTeaseProps | undefined {
  if (data && typeof data === "object") {
    const props: HeroTeaseProps = {};

    if (data.title) props.title = data.title;
    if (data.description) props.subheading = data.description;
    if (isSearchHitType<SearchDisplay>("modern", data)) {
      if (data.display?.thumbnail?.src) {
        props.media = { image: { src: data.display.thumbnail.src } };
      }

      if (data.display?.byline) {
        const byline: Array<BylinePerson> = [];

        data.display.byline.forEach((author) => {
          if (author.label) {
            byline.push({ name: author.label, link: author.link });
          }
        });

        props.byline = {
          publicationDate: data.display.date,
          authors: byline,
        };
      }

      if (data.display?.overline) {
        props.overline = {
          label: data.display.overline.label,
          link: data.display.overline.link,
        };
      }
    }

    if (data.url) {
      props.link = data.url;
    }
    return props;
  }
}

export function createLegacySearchHeroTease(
  data?: LegacySearchHit,
): HeroTeaseProps | undefined {
  if (data && typeof data === "object") {
    const props: HeroTeaseProps = {};

    if (data.HBSTitle) props.title = data.HBSTitle;
    if (data.HBSDescription) props.subheading = data.HBSDescription;
    if (data.HBSThumbnail) props.media = { image: { src: data.HBSThumbnail } };
    if (data.HBSByline) {
      let authors = data.HBSByline;

      if (authors.startsWith("by ")) authors = authors.substring(3);
      if (authors.startsWith("By: ")) authors = authors.substring(4);
      if (authors.startsWith("Re: ")) authors = authors.substring(4);

      props.byline = {
        publicationDate: data.HBSPubDate,
        authors: [{ name: authors }],
      };
    }

    if (data.HBSLink) {
      props.link = data.HBSLink;

      if (data.HBSSource) {
        props.overline = { label: data.HBSSource, link: data.HBSLink };
      }
    }
    return props;
  }
}
