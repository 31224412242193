import { MissionTopperCardEntry } from "frontend/contentful/schema/toppers";
import { createContentfulProps } from "../../../lib/create-contentful-props";
import { MissionTopperTextPartDetail } from "design-system/sites/home/mission-topper/mission-topper";
import { RenderContentfulRichText } from "../../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../../primitives/media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createMissionTopperTextPartDetailProps = createContentfulProps<
  MissionTopperCardEntry,
  MissionTopperTextPartDetail
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl } = useContentful();

  const media = createMediaAssetProps(entry.fields.media);

  if (media) {
    return {
      media,
      cta: {
        text: <RenderContentfulRichText document={entry.fields.description} />,
        linkText: entry.fields?.title,
        linkUrl: getEntryUrl(entry.fields.link),

        editAttributes: {
          url: createEditAttributes({
            entry: entry.fields.link,
            fieldId: "url",
          }),
          text: createEditAttributes({ entry, fieldId: "description" }),
          linkText: createEditAttributes({ entry, fieldId: "title" }),
        },
      },
    };
  }
});
