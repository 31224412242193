import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { WKFeaturedTermTopperEntry } from "frontend/contentful/schema/toppers";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { FeaturedTermTopperProps } from "design-system/sites/hbswk/featured-term-topper/featured-term-topper";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { createFeaturedFacultyProps } from "../utils/featured-faculty-props";
import { createUseSearchProps } from "../utils/use-search.props";
import { useSearch } from "frontend/hooks/use-search";
import { FeaturedTermTopperFacultyHit, PersonTease } from "frontend/types";
import { RelatedPerson } from "design-system/components/pages/article-page/article-related-people/article-related-people";
import { useContentful } from "frontend/hooks/use-contentful";

export const createWKFeaturedTermTopperProps = createContentfulProps<
  WKFeaturedTermTopperEntry,
  FeaturedTermTopperProps
>(({ entry, createEditAttributes }) => {
  const { getIsPersonPastHbsFaculty } = useContentful();

  let featuredFaculty: NonNullable<
    FeaturedTermTopperProps["featuredFaculty"]
  >["people"] = [];
  const props: FeaturedTermTopperProps = {
    title: entry.fields.title,
    intro: entry.fields.description ? (
      <RenderContentfulRichText document={entry.fields.description} />
    ) : undefined,
    mediaAssetImage: createMediaAssetProps(entry.fields.media)?.image,
    theme: entry.fields.theme,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      intro: createEditAttributes({ entry, fieldId: "description" }),
    },
  };

  if (entry.fields.faculty?.length) {
    entry.fields.faculty?.forEach((person) => {
      if (getIsPersonPastHbsFaculty(person)) return;
      const featuredFacultyProps = createFeaturedFacultyProps(person);
      if (featuredFacultyProps) {
        featuredFaculty.push(featuredFacultyProps);
      }
    });
  } else {
    if (entry.fields.query) {
      const searchProps = createUseSearchProps(entry.fields.query);
      const { data } = useSearch<FeaturedTermTopperFacultyHit>(searchProps);
      const facultyPersonSet = new Set<string>();
      const queryFeaturedFaculty: Array<RelatedPerson> = [];

      if (data?.hits) {
        data.hits.forEach((hit: FeaturedTermTopperFacultyHit) => {
          if (hit.display?.personTeases) {
            hit.display.personTeases.forEach((personTease: PersonTease) => {
              if (!facultyPersonSet.has(personTease.name)) {
                facultyPersonSet.add(personTease.name);

                const featuredFacultyProps: RelatedPerson = {
                  name: personTease.name,
                  title: personTease.title ?? "",
                  link: personTease.url,
                };
                if (personTease.thumbnail?.src) {
                  featuredFacultyProps.portrait = {
                    image: personTease.thumbnail,
                  };
                }

                // Only show current hbs faculty: non-faculty do not have links
                if (personTease.url) {
                  queryFeaturedFaculty.push(featuredFacultyProps);
                }
              }
            });
          }
        });

        featuredFaculty = queryFeaturedFaculty.slice(0, 5);
      }
    }
  }

  const cta = createCtaLinkProps(entry.fields.facultyLink);

  if (featuredFaculty.length) {
    props.featuredFaculty = {
      heading: entry.fields.facultyHeading,
      cta,
      people: featuredFaculty,
      editAttributes: {
        heading: createEditAttributes({
          entry,
          fieldId: "facultyHeading",
        }),
      },
    };
  }

  return props;
});
