import { CardTopperProps } from "design-system/components/toppers/card-topper/card-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { CardTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaLinkProps } from "../primitives/cta-link.props";

export const createCardTopperProps = createContentfulProps<
  CardTopperEntry,
  CardTopperProps
>(({ entry, createEditAttributes }) => {
  const props: CardTopperProps = {
    type: "Card",
    title: entry.fields.title || "",
    subtitle: <RenderContentfulRichText document={entry.fields.subtitle} />,
    ctaList: {
      items: entry.fields.ctaList
        ? entry.fields.ctaList.map((linkEntry) => {
            const props = createCtaLinkProps(linkEntry);
            if (!props) return { children: "" };
            return props;
          })
        : undefined,
    },
    ctaButton: createCtaLinkProps(entry.fields.ctaButton),
    media: createMediaAssetProps(entry.fields.media),
    theme: entry.fields.theme,
    spotTheme: entry.fields.spotTheme,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
    },
  };

  return props;
});
