import { ArticleTopperProps } from "design-system/components/toppers/article-topper/article-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SimpleArticleTopperEntry } from "frontend/contentful/schema/toppers";

export const createSimpleArticleTopperProps = createContentfulProps<
  SimpleArticleTopperEntry,
  ArticleTopperProps
>(({ entry }) => {
  const props: ArticleTopperProps = {
    theme: entry.fields.theme,
  };

  return props;
});
