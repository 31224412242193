import { LargeAssetTopperProps } from "design-system/components/toppers/large-asset-topper/large-asset-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { LargeAssetTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaListProps } from "../primitives/cta-list.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { usePageProps } from "frontend/hooks/use-page-props";

export const createLargeAssetTopperProps = createContentfulProps<
  LargeAssetTopperEntry,
  LargeAssetTopperProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl, getEntry, site } = useContentful();
  const { request } = usePageProps();

  const props: LargeAssetTopperProps = {
    spotTheme: entry.fields.spotTheme,
    title: entry.fields.title,
    hiddenTitle:
      request.path.substring(1) === site?.fields.slug
        ? site.fields.title
        : undefined,
    subtitle: entry.fields.subtitle ? (
      <RenderContentfulRichText document={entry.fields.subtitle} />
    ) : undefined,
    asset: entry.fields.media
      ? createMediaAssetProps(entry.fields.media)
      : undefined,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
    },
  };

  const overlineLinkEntry = getEntry(entry.fields.overlineLink);

  if (overlineLinkEntry) {
    props.overline = {
      text: overlineLinkEntry?.fields.text,
      link: getEntryUrl(entry.fields.overlineLink),
      editAttributes: {
        text: createEditAttributes({
          entry: overlineLinkEntry,
          fieldId: "text",
        }),
      },
    };
  }

  if (!overlineLinkEntry && entry.fields.overlineText) {
    props.overline = {
      text: entry.fields.overlineText,
      editAttributes: {
        text: createEditAttributes({ entry, fieldId: "overlineText" }),
      },
    };
  }

  const buttonEntry = getEntry(entry.fields.button);

  if (buttonEntry) {
    props.ctaButton = {
      href: getEntryUrl(entry.fields.button),
      children: buttonEntry.fields.text,
      editAttributes: createEditAttributes({
        entry: buttonEntry,
        fieldId: "text",
      }),
    };
  }

  if (!buttonEntry && entry.fields.links) {
    props.ctaList = createCtaListProps(entry.fields.links);
  }

  return props;
});
