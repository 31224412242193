import { SearchTopperProps } from "design-system/components/toppers/search-topper/search-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SearchTopperEntry } from "frontend/contentful/schema/toppers";

export const createSearchTopperProps = createContentfulProps<
  SearchTopperEntry,
  SearchTopperProps
>(({ entry, createEditAttributes }) => {
  const props: SearchTopperProps = {
    theme: entry.fields.theme,
    title: entry.fields.title,
    placeholder: entry.fields.placeholder,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      placeholder: createEditAttributes({ entry, fieldId: "placeholder" }),
    },
  };

  return props;
});
