import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { createSimpleArticleTopperProps } from "./simple-article-topper.props";
import { createBigArtTopperProps } from "./big-art-topper.props";
import { createSplitArticleTopperProps } from "./split-article-topper.props";
import { createCardTopperProps } from "./card-topper.props";
import { createMissionTopperProps } from "./mission-topper/mission-topper.props";
import { createStatisticsTopperProps } from "./statistics-topper.props";
import { createSplitPageTopperProps } from "./split-page-topper.props";
import { createSplitPageTopperPodcastProps } from "./split-page-topper-podcast.props";
import { createSimplePageTopperProps } from "./simple-page-topper.props";
import { createMediaTopperProps } from "./media-topper.props";
import { createEditorialTopperProps } from "./editorial-topper.props";
import {
  ParticipantStoryTopperOptions,
  createParticipantStoryTopperProps,
} from "./participant-story-topper.props";
import { createSearchTopperProps } from "./search-topper.props";
import { createLargeAssetTopperProps } from "./large-asset-topper.props";
import { ContentfulEntry } from "frontend/contentful/schema/sys";
import { LazyComponentProps } from "design-system/components/system/lazy-component/lazy-component";
import {
  BigArtTopperEntry,
  CardTopperEntry,
  MediaTopperEntry,
  MissionTopperEntry,
  SearchTopperEntry,
  SimpleArticleTopperEntry,
  SimplePageTopperEntry,
  SplitArticleTopperEntry,
  SplitPageTopperEntry,
  StatisticsTopperEntry,
  EditorialTopperEntry,
  ParticipantStoryTopperEntry,
  LargeAssetTopperEntry,
  SplitPageTopperPodcastEntry,
  SplitSearchTopperEntry,
  FeaturedTermTopperEntry,
  WKFeaturedTermTopperEntry,
  SelectTopperEntry,
  WKProfileTopperEntry,
} from "frontend/contentful/schema/toppers";
import { createSplitSearchTopperProps } from "./split-search-topper.props";
import { createFeaturedTermTopperProps } from "./featured-term-topper.props";
import { createWKFeaturedTermTopperProps } from "./wk-featured-term-topper.props";
import { createSelectTopperProps } from "./select-topper.props";
import { createWKProfileTopperProps } from "./wk-profile-topper.props";

export type LazyComponentOptions = ParticipantStoryTopperOptions;

/**
 * Since pages require topper props to dynamically render, this function selects the correct topper prop generator
 * for the entry type passed.
 *
 * A better solution would possibly be to adjust pages to accept a rendered react topper element, so we can use `<RenderContentfulEntry />` instead.
 */
export const createLazyTopperProps = createContentfulProps<
  ContentfulEntry,
  LazyComponentProps,
  LazyComponentOptions
>(({ entry, options }) => {
  if (isContentType<SimpleArticleTopperEntry>("simpleArticleTopper", entry)) {
    return {
      name: "ArticleTopper",
      props: createSimpleArticleTopperProps(entry),
    };
  }

  if (isContentType<BigArtTopperEntry>("bigArtTopper", entry)) {
    return { name: "ArticleTopper", props: createBigArtTopperProps(entry) };
  }

  if (isContentType<SplitArticleTopperEntry>("splitArticleTopper", entry)) {
    return { name: "SplitTopper", props: createSplitArticleTopperProps(entry) };
  }

  if (
    isContentType<SplitPageTopperPodcastEntry>("splitPageTopperPodcast", entry)
  ) {
    return {
      name: "SplitTopper",
      props: createSplitPageTopperPodcastProps(entry),
    };
  }

  if (isContentType<SplitSearchTopperEntry>("splitSearchTopper", entry)) {
    return { name: "SplitTopper", props: createSplitSearchTopperProps(entry) };
  }

  if (isContentType<CardTopperEntry>("cardTopper", entry)) {
    return { name: "CardTopper", props: createCardTopperProps(entry) };
  }

  if (isContentType<MissionTopperEntry>("missionTopper", entry)) {
    return { name: "MissionTopper", props: createMissionTopperProps(entry) };
  }

  if (isContentType<MediaTopperEntry>("mediaTopper", entry)) {
    return { name: "MediaTopper", props: createMediaTopperProps(entry) };
  }

  if (isContentType<SimplePageTopperEntry>("simplePageTopper", entry)) {
    return {
      name: "SimplePageTopper",
      props: createSimplePageTopperProps(entry),
    };
  }

  if (isContentType<SplitPageTopperEntry>("splitPageTopper", entry)) {
    return { name: "SplitTopper", props: createSplitPageTopperProps(entry) };
  }

  if (isContentType<EditorialTopperEntry>("editorialTopper", entry)) {
    return {
      name: "EditorialTopper",
      props: createEditorialTopperProps(entry),
    };
  }

  if (isContentType<StatisticsTopperEntry>("statisticsTopper", entry)) {
    return {
      name: "StatisticsTopper",
      props: createStatisticsTopperProps(entry),
    };
  }

  if (
    isContentType<ParticipantStoryTopperEntry>("participantStoryTopper", entry)
  ) {
    return {
      name: "ParticipantStoryTopper",
      props: createParticipantStoryTopperProps(entry, options),
    };
  }

  if (isContentType<SearchTopperEntry>("searchTopper", entry)) {
    return { name: "SearchTopper", props: createSearchTopperProps(entry) };
  }

  if (isContentType<LargeAssetTopperEntry>("largeAssetTopper", entry)) {
    return {
      name: "LargeAssetTopper",
      props: createLargeAssetTopperProps(entry),
    };
  }

  if (isContentType<FeaturedTermTopperEntry>("featuredTermTopper", entry)) {
    return {
      name: "FeaturedTermTopper",
      props: createFeaturedTermTopperProps(entry),
    };
  }

  if (isContentType<WKFeaturedTermTopperEntry>("wkFeaturedTermTopper", entry)) {
    return {
      name: "FeaturedTermTopper",
      props: createWKFeaturedTermTopperProps(entry),
    };
  }

  if (isContentType<SelectTopperEntry>("selectTopper", entry)) {
    return {
      name: "SelectTopper",
      props: createSelectTopperProps(entry),
    };
  }

  if (isContentType<WKProfileTopperEntry>("wkProfileTopper", entry)) {
    return {
      name: "ProfileTopper",
      props: createWKProfileTopperProps(entry),
    };
  }
});
