import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import {
  MissionTopperCardEntry,
  MissionTopperEntry,
} from "frontend/contentful/schema/toppers";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  MissionTopperProps,
  MissionTopperTextPart,
} from "design-system/sites/home/mission-topper/mission-topper";
import { createMediaAssetProps } from "frontend/contentful/components/primitives/media-asset.props";
import { createMissionTopperTextPartDetailProps } from "frontend/contentful/components/toppers/mission-topper/mission-topper-text-part-details.props";

import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { useContentful } from "frontend/hooks/use-contentful";
import { ContentfulEntry } from "frontend/contentful/schema/sys";

export const createMissionTopperProps = createContentfulProps<
  MissionTopperEntry,
  MissionTopperProps
>(({ entry, createEditAttributes }) => {
  const { getEntry } = useContentful();

  const defaultMedia = createMediaAssetProps(entry.fields.media);

  if (!defaultMedia) return;

  const parts: Array<MissionTopperTextPart> = [];

  if (entry.fields.text && Array.isArray(entry.fields.text.content)) {
    entry.fields.text.content.forEach((p) => {
      if (p?.nodeType === BLOCKS.PARAGRAPH) {
        p.content.forEach((item) => {
          const part: MissionTopperTextPart = { text: "" };

          if (item.nodeType === "text" && item.value) {
            part.text = item.value;
          }

          if (item.nodeType === INLINES.ENTRY_HYPERLINK) {
            if (item.content[0]?.nodeType === "text") {
              part.text = item.content[0].value;
            }

            const target = getEntry(item.data?.target as ContentfulEntry);

            if (
              isContentType<MissionTopperCardEntry>("missionTopperCard", target)
            ) {
              part.details = createMissionTopperTextPartDetailProps(target);
            }
          }

          if (part.text) parts.push(part);
        });
      }
    });
  }

  return {
    parts,
    defaultMedia,
    editAttributes: {
      text: createEditAttributes({ entry, fieldId: "text" }),
      defaultMedia: createEditAttributes({
        entry: entry.fields.media,
        fieldId: "asset",
      }),
    },
  };
});
