import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { HeroTeaseProps } from "design-system/components/blocks/hero-tease/hero-tease";
import { createMediaAssetProps } from "../../primitives/media-asset.props";
import { createBylineProps } from "../../primitives/byline.props";
import { RenderContentfulRichText } from "../../utils/render-contentful-rich-text";
import { useSearch } from "frontend/hooks/use-search";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  ArticlePageEntry,
  DetailPageEntry,
  LandingPageEntry,
} from "frontend/contentful/schema/pages";
import { QueryEntry } from "frontend/contentful/schema/semantics";
import { createUseSearchProps } from "../../utils/use-search.props";
import { createSearchHeroTease } from "frontend/contentful/search/search-hero-tease";
import { SearchQuery, LegacySearchHit } from "frontend/types";
import { useContentful } from "frontend/hooks/use-contentful";

export const createHeroTeaseProps = createContentfulProps<
  ArticlePageEntry | QueryEntry | LandingPageEntry | DetailPageEntry,
  HeroTeaseProps
>(({ entry, createEditAttributes }) => {
  const { getEntry, getEntryUrl } = useContentful();

  /* QueryEntry */

  let searchProps: SearchQuery | undefined | null = null;

  if (isContentType<QueryEntry>("query", entry)) {
    searchProps = createUseSearchProps(entry);
    if (searchProps) searchProps.size = 1;

    const search = useSearch<LegacySearchHit>(searchProps);

    const searchHeroTeaseProps = createSearchHeroTease(search?.data?.hits[0]);
    if (searchHeroTeaseProps) return searchHeroTeaseProps;
  }

  /* ArticlePageEntry */

  if (isContentType<ArticlePageEntry>("articlePage", entry)) {
    const props: HeroTeaseProps = {
      title: entry.fields.title,
      link: getEntryUrl(entry),
      byline: createBylineProps(entry),
      media: createMediaAssetProps(entry.fields.leadImage),
      subheading: (
        <RenderContentfulRichText
          document={entry.fields.shortDescription || entry.fields.description}
          disableParagraphs
        />
      ),
    };

    if (props.byline) props.byline.headshots = true;

    const overline = getEntry(entry.fields.overline);

    if (overline?.fields.text && overline.fields.url) {
      props.overline = {
        label: overline.fields.text,
        link: getEntryUrl(overline),
      };
    }

    props.editAttributes = {
      subheading: createEditAttributes({ entry, fieldId: "shortDescription" }),
      overline: createEditAttributes({
        entry: overline,
        fieldId: "text",
      }),
    };

    return props;
  }

  /* LandingPageEntry */

  if (isContentType<LandingPageEntry>("landingPage", entry)) {
    return {
      title: entry.fields.title,
      link: getEntryUrl(entry),
    };
  }

  /* DetailPageEntry */

  if (isContentType<DetailPageEntry>("detailPage", entry)) {
    return {
      title: entry.fields.title,
      link: getEntryUrl(entry),
    };
  }
});
