import { StatisticsTopperProps } from "design-system/components/toppers/statistics-topper/statistics-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { StatisticsTopperEntry } from "frontend/contentful/schema/toppers";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createCtaListProps } from "../primitives/cta-list.props";
import { createStatisticProps } from "../semantics/statistic.props";

export const createStatisticsTopperProps = createContentfulProps<
  StatisticsTopperEntry,
  StatisticsTopperProps
>(({ entry, createEditAttributes }) => {
  const props: StatisticsTopperProps = {
    title: entry.fields.title,
    theme: entry.fields.theme,
    asset: createMediaAssetProps(entry.fields.media),
    subheading: (
      <RenderContentfulRichText
        document={entry.fields.subtitle}
        disableParagraphs
      />
    ),
    cta: createCtaListProps(entry.fields.links),
    stats: [],

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
    },
  };

  entry.fields.statistics?.forEach((entry) => {
    const statisticProps = createStatisticProps(entry);
    if (statisticProps) props.stats?.push(statisticProps);
  });

  return props;
});
