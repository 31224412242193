import { StatisticProps } from "design-system/components/primitives/statistic/statistic";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { StatisticEntry } from "frontend/contentful/schema/semantics";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";

export const createStatisticProps = createContentfulProps<
  StatisticEntry,
  StatisticProps
>(({ entry, createEditAttributes }) => {
  const props: StatisticProps = {
    figure: entry.fields.value,
    unit: entry.fields.suffix,
    prefixedUnit: entry.fields.prefix,
    description: (
      <RenderContentfulRichText
        document={entry.fields.title}
        disableParagraphs
      />
    ),

    editAttributes: {
      value: createEditAttributes({ entry, fieldId: "value" }),
      description: createEditAttributes({ entry, fieldId: "title" }),
    },
  };
  return props;
});
