import { SplitTopperProps } from "design-system/components/toppers/split-topper/split-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SplitArticleTopperEntry } from "frontend/contentful/schema/toppers";

export const createSplitArticleTopperProps = createContentfulProps<
  SplitArticleTopperEntry,
  SplitTopperProps
>(({ entry }) => {
  const props: SplitTopperProps = {
    variant: "article",
    spotTheme: entry.fields.spotTheme,
  };
  return props;
});
