import { SimplePageTopperProps } from "design-system/components/toppers/simple-page-topper/simple-page-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SimplePageTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaLinkProps } from "../primitives/cta-link.props";

export const createSimplePageTopperProps = createContentfulProps<
  SimplePageTopperEntry,
  SimplePageTopperProps
>(({ entry, createEditAttributes }) => {
  return {
    type: "Simple",
    title: entry.fields.title,
    theme: entry.fields.theme,
    intro: <RenderContentfulRichText document={entry.fields.subtitle} />,
    media: createMediaAssetProps(entry.fields.media),
    cta: createCtaLinkProps(entry.fields.cta),

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
    },
  };
});
