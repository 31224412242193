import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { FeaturedTermTopperEntry } from "frontend/contentful/schema/toppers";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { FeaturedTermTopperProps } from "design-system/sites/hbswk/featured-term-topper/featured-term-topper";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { createFeaturedFacultyProps } from "../utils/featured-faculty-props";

export const createFeaturedTermTopperProps = createContentfulProps<
  FeaturedTermTopperEntry,
  FeaturedTermTopperProps
>(({ entry, createEditAttributes }) => {
  const props: FeaturedTermTopperProps = {
    title: entry.fields.title,
    intro: entry.fields.description ? (
      <RenderContentfulRichText document={entry.fields.description} />
    ) : undefined,
    mediaAssetImage: createMediaAssetProps(entry.fields.media)?.image,
    theme: entry.fields.theme,
    featuredFaculty:
      entry.fields.facultyHeading ||
      entry.fields.facultyLink ||
      entry.fields.faculty
        ? {
            heading: entry.fields.facultyHeading,
            cta: createCtaLinkProps(entry.fields.facultyLink),
            people: [],
            editAttributes: {
              heading: createEditAttributes({
                entry,
                fieldId: "facultyHeading",
              }),
            },
          }
        : undefined,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      intro: createEditAttributes({ entry, fieldId: "description" }),
    },
  };

  entry.fields.faculty?.forEach((person) => {
    const featuredFacultyProps = createFeaturedFacultyProps(person);
    if (featuredFacultyProps) {
      props.featuredFaculty?.people?.push(featuredFacultyProps);
    }
  });

  return props;
});
