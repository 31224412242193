import { MediaTopperProps } from "design-system/components/toppers/media-topper/media-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { MediaTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { usePageProps } from "frontend/hooks/use-page-props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createMediaTopperProps = createContentfulProps<
  MediaTopperEntry,
  MediaTopperProps
>(({ entry, createEditAttributes }) => {
  const { request } = usePageProps();
  const { site } = useContentful();

  const props: MediaTopperProps = {
    assets: [],
    title: entry.fields.title,
    hiddenTitle:
      request.path.substring(1) === site?.fields.slug ||
      (request.path === "/" && site?.fields.domain !== "www")
        ? site?.fields.title
        : undefined,
    theme: entry.fields.theme,
    isSeamless: entry.fields.isSeamless,
    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
    },
  };

  const cta = createCtaLinkProps(entry.fields.ctaButton);
  if (cta) {
    props.cta = cta;
    props.cta.type = "primary-button";
  }

  if (entry.fields.subtitle) {
    props.subtitle = (
      <RenderContentfulRichText document={entry.fields.subtitle} />
    );
  }

  entry.fields.media?.forEach((media) => {
    const mediaProps = createMediaAssetProps(media);
    if (mediaProps) props.assets.push(mediaProps);
  });

  return props;
});
