import { SplitTopperProps } from "design-system/components/toppers/split-topper/split-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SplitPageTopperPodcastEntry } from "frontend/contentful/schema/toppers";
import { useContentful } from "frontend/hooks/use-contentful";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { CtaLinkProps } from "design-system/components/primitives/cta-link/cta-link";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";

export const createSplitPageTopperPodcastProps = createContentfulProps<
  SplitPageTopperPodcastEntry,
  SplitTopperProps
>(({ entry }) => {
  const { getEntry, getEntries } = useContentful();
  const props: SplitTopperProps = {
    isPodcast: true,
    theme: entry.fields.theme,
  };

  const podcastSeries = getEntry(entry.fields.podcastSeries);

  if (podcastSeries) {
    props.title = podcastSeries.fields.title;

    props.subtitle = podcastSeries.fields.description && (
      <RenderContentfulRichText document={podcastSeries.fields.description} />
    );

    props.mediaAsset = createMediaAssetProps(podcastSeries.fields.relatedImage);

    const followUs: Array<CtaLinkProps> = [];

    getEntries(podcastSeries.fields.socialMediaLink).forEach((item) => {
      if (item.fields.socialMedia && item.fields.url) {
        followUs.push({
          children: item.fields.socialMedia,
          href: item.fields.url,
        });
      }
    });

    props.ctaList = { items: followUs };
  }

  return props;
});
