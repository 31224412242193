import {
  SelectTopperItem,
  SelectTopperProps,
} from "design-system/components/toppers/select-topper/select-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SelectTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { useContentful } from "frontend/hooks/use-contentful";
import { useSearchDropdowns } from "frontend/hooks/use-search-dropdowns";
import { ChangeEvent } from "react";

export const createSelectTopperProps = createContentfulProps<
  SelectTopperEntry,
  SelectTopperProps
>(({ entry, createEditAttributes }) => {
  const { getEntries } = useContentful();
  const { selectedOptions, onSelectedOptionChange } = useSearchDropdowns();

  const props: SelectTopperProps = {
    title: entry.fields.title || "",
    description: entry.fields.description ? (
      <RenderContentfulRichText document={entry.fields.description} />
    ) : undefined,
    theme: entry.fields.theme,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      description: createEditAttributes({ entry, fieldId: "description" }),
    },
  };

  const facets = getEntries(entry.fields.facets);
  const items: Array<SelectTopperItem> = [];

  facets.forEach((facet) => {
    if (facet.fields.field) {
      const options = [];
      if (facet.fields.terms) {
        options.push(
          { label: "Select One", value: "" },
          ...facet.fields.terms.map((term) => ({ label: term, value: term })),
        );
      }

      const selectedOption = selectedOptions[facet.fields.field];

      items.push({
        key: facet.fields.field,
        label: facet.fields.label || "",
        options: options,

        selectedOption: selectedOption || "",
        onChange: (event: ChangeEvent<HTMLSelectElement>) => {
          onSelectedOptionChange(facet.fields.field!, event.target.value);
        },

        editAttributes: {
          label: createEditAttributes({ entry, fieldId: "label" }),
        },
      });
    }
  });

  props.items = items;

  return props;
});
