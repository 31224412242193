import { EditorialTopperProps } from "design-system/components/toppers/editorial-topper/editorial-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { EditorialTopperEntry } from "frontend/contentful/schema/toppers";
import { createHeroTeaseProps } from "../blocks/teases/hero-tease.props";
import { createArticleTeaseProps } from "../blocks/teases/article-tease.props";
import { createSearchArticleTease } from "frontend/contentful/search/search-article-tease";
import { createSearchHeroTease } from "frontend/contentful/search/search-hero-tease";
import { createUseSearchProps } from "../utils/use-search.props";
import { LegacySearchHit, ModernSearchHit } from "frontend/types";
import { useSearch } from "frontend/hooks/use-search";
import { usePageProps } from "frontend/hooks/use-page-props";
import { useContentful } from "frontend/hooks/use-contentful";
import { getSiteId } from "frontend/lib/get-site-id";

export const createEditorialTopperProps = createContentfulProps<
  EditorialTopperEntry,
  EditorialTopperProps
>(({ entry }) => {
  const { getEntry } = useContentful();

  const props: EditorialTopperProps = {
    theme: entry.fields.theme,
    stories: [],
  };

  const { entry: pageEntry } = usePageProps();
  const sitePage = usePageProps();
  const page = getEntry(pageEntry);
  const site = getEntry(sitePage.site);
  const siteId = getSiteId(site);
  props.pageTitle = page?.fields.title;

  const allTitles: Array<string> = [];
  let articleTitle = "";

  const [heroArticle, ...articles] = entry.fields.articles || [];

  if (heroArticle) {
    props.heroTeaseContent = createHeroTeaseProps(heroArticle);

    if (props.heroTeaseContent?.title) {
      articleTitle = props.heroTeaseContent.title;
    }
  }

  if (!entry.fields.query) {
    articles?.slice(0, 4).forEach((story) => {
      const articleTeaseProps = createArticleTeaseProps(story);
      if (articleTeaseProps) props.stories!.push(articleTeaseProps);
    });
  }

  const searchProps = createUseSearchProps(entry?.fields?.query);
  if (searchProps && !searchProps.size) searchProps.size = 5;
  if (searchProps?.size) searchProps.size = searchProps.size + 2; //Temporary: Get 2 additional records incase of dupes

  const search = useSearch<ModernSearchHit | LegacySearchHit>(searchProps);

  const [searchArticleHero, ...searchArticles] = search.data?.hits || [];

  if (searchArticleHero && !entry.fields.articles) {
    props.heroTeaseContent = createSearchHeroTease(searchArticleHero);

    if (props.heroTeaseContent?.title) {
      articleTitle = props.heroTeaseContent.title;
    }
  }
  let counter = 0;
  const storiesLimit = 4 - (entry.fields.articles ? 1 : 0);
  searchArticles?.forEach((result) => {
    const searchArticleTeaseProps = createSearchArticleTease(result, siteId);
    const title = searchArticleTeaseProps?.title?.toString();

    if (
      searchArticleTeaseProps &&
      title &&
      searchArticleTeaseProps.title !== articleTitle &&
      !allTitles.includes(title) &&
      counter < storiesLimit
    ) {
      props.stories!.push(searchArticleTeaseProps);
      allTitles.push(title);
      counter++;
    }
  });

  if (props.heroTeaseContent) {
    props.heroTeaseContent.isTopper = true;

    if (props.heroTeaseContent.media) {
      props.heroTeaseContent.layout = "side-by-side-media-left";
    } else {
      props.heroTeaseContent.layout = "text-only";
    }
  }

  return props;
});
